import React from 'react';

const DevNotesButton = ({ isTempNotesPopupOpen, setTempNotesPopupOpen }) => {
  return (
    <button
      className='temp_notes'
      title='toggle temporary dev notes'
      onClick={() => setTempNotesPopupOpen(!isTempNotesPopupOpen)}
    >
      Dev.Notes
    </button>
  );
};

export default DevNotesButton;
