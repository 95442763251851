import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import ThanksComponent from '../../components/ThanksComponent';

const EXAMPLE = () => {

  const navigate = useNavigate();


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 24;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Фаришта билан  </p>
            <p>олишув</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}


            >
              <p>
                <sup>1</sup>  Яъқуб пайғамбар ўшанда Оллоҳнинг фаришталарини кўрганлар ва ўша жойга Мақомийн деб от берганлар.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              Яъқуб (а.с.) сафарни<sup>1</sup> давом эттирдилар. Ўша пайтда Ийсу (р.а.) —   Яъқуб (а.с.) нинг эгизак акаси — Эдўм юртида, Сеир деган жойда яшаётган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> АКАСИГА ХАБАР ЮБОРГАНИ </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Яъқуб акасининг олдига элчилар жўнатиб, уларга бундай деб тайинладилар: «Акамнинг олдига бориб: “Бизни қулингиз Яъқуб юборди. У шу кунгача тоғангиз Лобоннинг уйида турган эди.  Моллар, эшаклар, қўй-эчкилар, қулу чўрилар эгаси бўлди. Мана энди отасининг юртига қайтиб келяпти. Сизни хабардор қилиш учун, раҳм-шафқатингизни ундан аямассиз, деган умидда бизни олдингизга юборди”, дейсизлар».
            </p>
            <p>
              Элчилар бориб, қайтиб келдилар ва: «Акангиз билан учрашдик. У ҳозир тўрт юзта одам билан буёққа келяпти», дедилар.
            </p>
            <p>
              Яъқуб (а.с.) қўрқиб кетдилар.
              Кейин, акам келиб ҳужум қилса, бир гуруҳи омон қолсин, деб одамлари билан чорвасини икки қисмга ажратдилар.
            </p>
            <p>
              Кейин тиз чўкиб дуога юз тутдилар: «Ё Оллоҳ! Бобом Иброҳимнинг, отам Исҳоқнинг илоҳи! Менга: “Ота юртингга қайт, Мен сенга барака бераман”, деб айтган эдинг-ку!
              Кўрсатган садоқатингга, шунча меҳру муҳаббатингга мен лойиқ эмасман. Уйдан чиққанимда, қўлимда ҳассамдан бошқа ҳеч нарса йўқ эди. Мана энди шунча одамларим, сурувларим бор.  Ё Оллоҳ, ялинаман, мени акамдан қутқар. У ҳужум қилиб, мени ҳам, хотин-бола-чақаларимни ҳам ўлдиради, деб қўрқяпман.  Сен менга: “Сени баракалайман, авлодларингни денгиз қумидай кўпайтираман”, деб ваъда берган эдинг-ку!»
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ИЙСУГА АЖРАТИЛГАН СОВҒАЛАР
            </p>
          </div>

          <div className='main_text'>
            <p>
              Ўша куни кечқурун Яъқуб (а.с.)  икки юзта эчки, йигирмата така, икки юзта қўй, йигирмата қўчқор,  бўталоқлари билан бирга ўттизта туя, қирқта сигир, ўнта буқа, йигирмата урғочи, ўнта нар эшакни акаси Ийсуга ҳадя қилиш учун чорвасидан айирдилар.
            </p>

            <p>
              Кейин уларни алоҳида-алоҳида қилиб ҳар бир подани биттадан хизматкорига топшириб: «Сизлар олдинда борасизлар. Орадаги масофани сақланглар», дедилар. Биринчи чўпонга:
              «Акам Ийсуга рўпара келганингда: “Кимнинг одамисан, қаерга кетяпсан, бу моллар кимники?” деб сўрайди.
              Шунда: “Укангиз Яъқубники. Буларни хўжайинимиз Ийсуга ҳадя учун юборди. Ўзи орқамиздан келяпти”, деб айтасан», дедилар.
            </p>
            <p>
              Бошқа подаларни ҳайдаб борадиган хизматкорларига ҳам: «Акамни кўрганингларда унга шундай денглар.  Кейин “Укангиз Яъқуб орқамиздан келяпти”, дейиш эсдан чиқмасин», деб тайинладилар. Олдиндан ҳадя юбориб, кўнглини олсам, зора юзма-юз бўлганда менга яхши муомала қилса, деб умид қилаётган эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              КЕЧАСИ БЎЛГАН ВОҚЕА
            </p>
          </div>

          <div className='main_text'>
            <p>
              Шундай қилиб ҳазрати Яъқуб, ҳадя учун ажратилган подаларни йўлга солдилар.
              Бир неча соатдан кейин, тунда, икки хотини, икки жорияси, ўн битта ўғли ва бор бойлигини Ябуқ дарёсининг нариги томонига ўтказдилар.
              Кейин якка ўзи чодирига қайтиб келдилар.
              Бир вақт  <span className="italic"> Худонинг фариштаси</span> одам  <span className="italic">қиёфасида</span> келиб тонг отгунча у киши билан кураш тушди.
            </p>
            <p>
              Охири, ҳазрати Яъқубни енголмаганини кўриб, қаттиқ зарба бериб, қуймичини бўғинидан чиқариб юборди.
              Кейин:
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Мени қўйвор, тонг ёришиб қолди, — деди.
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Йўқ, — дедилар Яъқуб (а.с.). — Менга барака бермасанг қўйиб юбормайман.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>Шунда у:</p>
          </div>

          <div className='main_text'>
            <p>
              —Отинг нима? — деб сўради.
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Яъқуб.
            </p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}


            >
              <p>
                <sup>2</sup> Шу сабабдан кейинчалик у кишининг авлодлари «Бани Исроил», яъни Исроилнинг фарзандлари деб аталган.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              —Энди сенинг отинг Яъқуб эмас, Исроил<sup>2</sup>  бўлади. Чунки сен бир умр инсонлар билан ва ҳатто фаришта билан олишдинг, Оллоҳнинг синовларига бардош бердинг.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Сенинг отинг нима? — деб сўрадилар Яъқуб (а.с.). Лекин у жавоб бермай:
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Нима қиласан? — деди. Сўнгра ҳазрати Яъқубни дуо қилди.
            </p>
            <p>
              (Яъқуб (а.с.) кейинчалик бу жойга, мен Оллоҳнинг фариштаси билан юзма-юз бўлдим, шундай бўлса ҳам тирикман деб, “Фануэл” деб от қўядилар.)
            </p>
            <p>
              Ҳазрати Яъқуб Фануэлдан узоқлашганида қуёш эндигина чиқаётган эди. Қуймичи лат егани учун у киши оқсаб-оқсаб қадам ташлаётган эдилар.
              (Шунинг учун Бани Исроил бугунгача қуймич пайини истеъмол қилмайди.)
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              АКА-УКАНИНГ УЧРАШИШИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Бир вақт Яъқуб (а.с.) акаси Ийсунинг узоқдан тўрт юз одам билан келаётганини кўрдилар-да
              болаларини оналарига топшириб, Билҳа билан Зилфани болалари билан олдинги сафга, Лайё билан болаларини ўртадаги сафга ва Роҳийла билан Юсуф (а.с.) ни орқадаги сафга жойлаштирдилар.  Ўзлари бўлса энг олдинга ўтиб, акасининг олдига боргунча етти марта ерга бош қўйиб, таъзим қилдилар.
            </p>
            <p>
              Ийсу (р.а.) югуриб укасининг олдига чиқди, қучоқлаб ўпди. Иккаласи ҳам йиғладилар.
              Кейин Ийсу (р.а.) хотинлар билан болаларга қараб:
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Булар ким? — деб сўради.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Каминага Оллоҳ ато этган болалар, — деб жавоб берди Яъқуб (а.с.).
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <p>
              Шунда Белҳа билан Зилфа болалари билан келиб Ийсуга таъзим қилдилар.
              Кейин Лайё билан фарзандлари, энг охирида Роҳийла билан Юсуф (а.с.) келиб таъзим қилди.
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <p>
              —Келаётганимда, йўлда кўрган подалар нима учун эди? — деб сўради Ийсу (р.а.) кейин.
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Мен уларни сизнинг марҳаматингизга сазовор бўлай деб жўнатган эдим, — дедилар ҳазрати Яъқуб.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Эй укажон, мол-давлатинг ўзингга буюрсин, — деди у. — Менинг сурувларим кўп.
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Йўқ, ака, агар ҳақиқатан марҳаматингизга эришган бўлсам, илтимос, арзимас совғамни қабул қилинг. Меҳрибонлик билан мени қабул қилдингиз,
              совғаларимни ҳам қабул қилинг. Минг қатла шукур, мол-давлатим ҳозир жуда кўп.
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <p>
              Яъқуб (а.с.) илтимос қилаверганларидан сўнг, Ийсу (р.а.) охири ҳадяларни қабул қилди.
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Қани, кетдик бўлмаса, — деди у кейин. — Бирга кетамиз.
            </p>
          </div>
          <p></p>
          <div className='main_text'>
            <p>
              Лекин ҳазрати Яъқуб:
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Кўриб турибсиз, ака, болалар ҳали ёш, бунинг устига янги туғилган қўзи-улоқлар бор. Бир кун қистаб ҳайдасак ҳаммаси ўлади.    Сиз олдинда кетаверинг. Мен болалар билан секин сурувларнинг орқасидан бораман. Сеирда кўришамиз, — дедилар.
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Майли, — деди акаси. — Бўлмаса одамларимдан бир нечтасини қолдириб кетай.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Йўқ, ҳожати йўқ, овора бўлманг, — деб Яъқуб (а.с.) кўнмадилар.
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <p>
              Шундан сўнг Ийсу (р.а.), одамлари билан ўша куни Сеирга қайтиб кетди.
              Яъқуб (а.с.) эса оиласини олиб Суккутга,
              ундан эса Канъон юртидаги Шакам шаҳрига соғ-саломат етиб келдилар.
            </p>
            <p className="italic">Шундай қилиб, кўриб турибсизки, ҳазрати Яъқуб Канъонга соғ-саломат қайтиб келдилар. Эсингиздами, Канъонни тарк этиб, акасидан қочиб кетаётганида у киши: “Агар Худо мени ... отам уйига тинчлик билан қайтарса, мен фақат Унга сиғинар эдим”, деб айтган эдилар.</p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЯЪҚУБ (А.С.) НИНГ ОллоҳГА ШУКРОНАСИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Яъқуб Шакамга келганларидан сўнг Шакам ҳокимининг ўғилларидан юз кумуш тангага шаҳар ташқарисидан ер сотиб олдилар ва қароргоҳ қурдилар.
              Кейин: “Сен менга паноҳ берган, мени соғ-саломат асраган Худосан” деб Оллоҳга қурбонликлар сўйдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ИСНОД...
            </p>
          </div>

          <div className='main_text'>
            <p>
              Шаҳар ҳокимининг оти ҳавий Ҳамўр бўлиб, унинг Шакам деган ўғли бор эди. Бир куни Лайёнинг қизи Дина шаҳарлик қизларнинг олдига боради. Шакам уни кўриб, номусига тажовуз қилади.

            </p>
            <p>
              Шакам Динани яхши кўриб қолган эди. Шунга ширин сўзлар билан унинг кўнглини топишга ҳаракат қилди.
              Кейин, бўлган воқеани отасига айтиб, шу қизни менга олиб беринг, деди.
            </p>
            <p>
              Қизининг номуси топталганини ҳазрати Яъқуб ҳам эшитдилар, лекин то ўғиллари келмагунча ҳеч нарса қилмадилар. Ўғиллари бу пайтда даштда, қўйларнинг олдида эди.
            </p>
            <p>
              Шу орада Ҳамўр, ўғли Шакамни олиб, Яъқуб (а.с.) нинг қароргоҳига келди. Меҳмонлар энди ўтирганларида, Динанинг акалари ҳам даштдан қайтиб келишди.
              Бўлган воқеани эшитиб улар қаттиқ аччиқландилар: бу иш жуда ёмон разил иш бўлиб, Исроил хонадони учун катта иснод эди. Ҳамўр:
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              —Ўғлим Шакам қизингизни севиб қолибди. Шунга қуда бўлсак, деган умид билан олдингизга келдим, — деб гап бошлади. — Бир-биримизга хеш бўлайлик, қиз бериб, қиз олайлик.
              Мана, юртимиз сизларга очиқ, хоҳлаган ерингизга ўрнашишингиз мумкин. Биз билан бирга яшаб, тижорат қиласиз, мол-мулк тўплайсиз...
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              —Илтимос, йўқ деманг, — деди Шакам ялиниб. — Нимаики десангиз бераман.
              Қанча қалин айтсангиз, қанча совға десангиз розиман. Фақат қизингизни менга берсангиз бўлди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              НАЙРАНГ...
            </p>
          </div>

          <div className='main_text'>
            <p>
              Яъқуб (а.с.) нинг ўғиллари қасос олиш ниятида эдилар.  Шунга маккорлик билан:
              «Биз синглимизни хатна бўлмаган одамга беролмаймиз, бу иш биз учун шармандалик, — деб жавоб бердилар.  — Қавмингиздаги ҳамма эркаклар бизга ўхшаб хатна бўлса бошқа гап.
              Ўшандагина сизлар билан қиз бериб-қиз олишимиз мумкин. Кейин сизлар билан бир қавм бўлиб яшаймиз. Хатна бўлишга рози бўлмасангизлар, биз синглимизни олиб, бу ердан кетамиз».
            </p>
            <p>
              Ҳамўр билан Шакам уларнинг шартини қабул қилдилар.  Шакам ўз уруғининг энг обрўли одами эди. Динани севиб қолгани учун вақтни бой бермай,  дарров отаси Ҳамўр билан шаҳар дарвозасига келиб, одамларга вазиятни тушунтирди:
            </p>
            <p>
              «Бу одамлар биз билан яхши муносабатда. Келинглар, юртимизда яшаб тижорат қилишига рухсат берайлик. Бир-биримизга қиз бериб, қиз олишамиз. Еримиз кенг, уларга ҳам, бизга ҳам етади.  Улар ҳам биз билан бир қавм бўлиб яшашга рози, фақат бир шарти бор. Шарти шуки, биздаги эркаклар ҳам уларга ўхшаб хатна бўлиши керак экан.  Агар шартини қабул қилсак, биз билан бирга яшайдилар. Сурувлари ҳам, бойликлари ҳам бизники бўлади».
            </p>
            <p>
              Дарвоза бошида ўтирган эркакларнинг ҳаммаси ҳокимнинг таклифини қабул қилдилар ва шаҳардаги ҳамма эркаклар хатна бўлди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ...ВА ҚАСОС
            </p>
          </div>

          <div className='main_text'>
            <p>
              Лекин учинчи куни, уларнинг хатна яраси битмай туриб, Динанинг акалари Шимъун билан Лобий, қилич кўтариб шаҳарга кирдилар ва ҳеч қандай қийинчиликсиз шаҳардаги ҳамма эркакларни тиғдан ўтказдилар.  Ҳамўр билан унинг ўғли Шакамни ҳам ўлдириб, Динани Шакамнинг уйидан олиб кетдилар.  Кейин сингилларининг номуси топталган шаҳарни талон-тарож қилишди.  Шаҳардан ва даштдан топган ҳамма қўй-эчкиларни, қорамолу эшакларни ўлжа олдилар.  Уйлардан чиққан ҳамма нарсани талаб, хотинлар ва болаларни асир қилдилар.
            </p>
            <p>
              Яъқуб алайҳиссалом бу ишлардан бехабар эди. Эшитиб, жуда қаттиқ ғазабландилар. «Сизлар мени ер билан бир қилдинглар! Расво қилдинглар! — деб бақирди Яъқуб (а.с.) Шимъун билан Лобийга. — Энди бу ердаги канъонийлар ҳам, перизийлар ҳам бизга душман бўлди. Ўзи камчиликмиз, биргалашиб ҳужум қилса, ҳеч қайсимиз тирик қолмаймиз!»
            </p>
            <p>
              «Синглимизга фоҳиша каби муомала қилса майлими?! — дейишди Шимъўн билан Лобий. — Иснодни ювишимиз керак эди».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ИМОННИНГ ЯНГИЛАНИШИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Шунда Оллоҳ таолодан ваҳий нозил бўлди: «Тур, Байтилга бор. Ўша ерда Менга атаб қурбонликлар сўй».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}


            >
              <p>
                <sup>3</sup>  Яъқуб (а.с.) нинг ўғиллари ўлжа олган мушрик аёллар бутларга сиғинар, Яъқуб (а.с.) нинг уйига ўзлари билан бут-санамларни олиб келган эдилар.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Яъқуб (а.с.) буни эшитиб аҳли байтига ва қўл остидаги қул-хизматкорларига: «Тайёрланинглар, кийимларингизни алмаштиринг, — дедилар. — Бут ва санамларни йўқ қилинг.<sup>3</sup>    Байтилга борамиз. Оғир кунларда мени қўллаган, қаерга борсам менга ҳамроҳлик қилган Оллоҳга ибодат қиламиз».
            </p>
            <p>
              Шундай қилиб у киши бутун хонаводасини ва хизматкорларини олиб, ибодат қилиш учун йўлга тушдилар.  Кетаётганларида, ён-атрофдаги шаҳар-қишлоқлардан ҳеч ким уларни таъқиб қилишга журъат этмади. Чунки Оллоҳ уларнинг кўнглига қўрқув солган эди.
            </p>
            <p>
              Байтилга келганларидан сўнг, Оллоҳ азза ва жалл Яъқуб (а.с.) га деди:
              «Мен ҳамма нарсага қодир Оллоҳман. Сендан халқ вужудга келади, ҳатто халқлар жамоаси! Наслингдан подшоҳлар дунёга келажак. Самарали бўл, кўпай.
              Бобонг Иброҳим ва отанг Исҳоқ мерос олган ерни Мен сенга ва сенинг авлодларингга бердим», деди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}


            >
              <p>
                <sup>4</sup>    Байтлаҳмга. Афрот — Байтлаҳмнинг эски оти.
              </p>
            </div>

            <p onClick={() => handleSupClick(4)}>
              Яъқуб (а.с.) кейин Байтилни тарк этиб, Афротга<sup>4</sup>  қараб йўлга чиқадилар. Йўлда Роҳийла Бибини тўлғоқ тутди ва иккинчи ўғли — Биняминни дунёга келтирди. Ўзи эса тўлғоқ дарди оғир келганидан азоб чекиб   жон берди. Яъқуб пайғамбар унинг жасадини ўша ерда, Афрот йўлида бир сағанага қўйдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}


            >
              <p>
                <sup>5</sup>    Иброҳим (а.с.) ва Соро Бибининг жасадлари шу ерда, Ҳабрунда ғорга қўйилган эди (Ибн Қутайба, “Маориф”, 16-бет; Салабий, “Ароис”, 97-бет.)
              </p>
            </div>

            <p onClick={() => handleSupClick(5)}>
              Йўлма-йўл сафар қилиб улар ниҳоят Исҳоқ (а.с.) яшаётган жойга, Ҳабрун<sup>5</sup>  шаҳрига келишди. (Ҳазрати Иброҳим ҳам шу ерда яшаган эдилар.)
            </p>

            <p>
              Исҳоқ (а.с.) бир юз саксон йил умр кўриб дунёдан кўз юмганида, ўғиллари Ийсу (р.а.) ва Яъқуб (а.с.) у кишини биргаликда боболари Иброҳим (а.с.) ётган сағанага қўйдилар.
            </p>
            <p></p>
          </div>

          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Фаришта билан олишув</p>
                </div>
              </div>


              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/joseph')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' >  Юсуф (а.с.) қиссаси </span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
          <ThanksComponent />
        </main>
        <aside> </aside>
      </div >
    </div >
  )
}

export default EXAMPLE
