import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import ThanksComponent from '../../components/ThanksComponent';

const EXAMPLE = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 11;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Мусо (а.с.) га берилган уч мўъжиза </p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>

          {/* <div className='paragraph_heading'>
            <p> paragraph_heading </p>
          </div> */}


          <div className='main_text'>
            <p>
              Шунда ҳазрати Мусо:
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Агар Бани Исроил менга ишонмаса ё гапимга қулоқ солмаса нима қиламан? — деб сўрадилар.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Қўлингдаги нима? — деди Оллоҳ.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Асо, — деди Мусо (а.с.).
            </p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup>     «Бас, (Мусо) уни ташлаган эди, баногоҳ, у юрадиган — жонли илон бўлиб қолди. (Оллоҳ) деди: «Уни ушла! Қўрқма, Биз уни аввалги ҳолига қайтарурмиз. Қўлингни қанотингга — қўлтиғингга тиқ, у ҳеч қандай зиён-заҳматсиз оппоқ бўлиб чиқади» (Тоҳо сураси, 20-22).
              </p>
            </div>

            <p onClick={() => handleSupClick(1)}>
              —Ерга ташла! — деди Оллоҳ.<sup>1</sup>
            </p>
            <p>
              Мусо (а.с.) қўлидаги асони ерга ташладилар. Асо шу лаҳзада илонга айланди.
            </p>
            <p className='boldItalic'>
              «Бас қачонки (Мусо) унинг илондек қимирлаётганини кўргач, ортига қарамай қочди. <span className="italic"> (Шунда Биз дедик:) </span> «Эй Мусо, қўрқма...» <span className="italic">(Намл сураси, 10).</span>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              —Қўлингни чўз, думидан ушла, — деди Оллоҳ. Мусо (а.с.) илоннинг думига қўл теккизиши билан, илон яна асога айланди!
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup>      Тавротда шу ўринда: “Мен Иброҳим билан, Исҳоқ билан, Яъқуб билан аҳд қилган Худоман”, деб ёзилган оят бор. Яъни, Қуръонда “ахёрлар” деб аталмиш бобокалонларимиз билан аҳд тузган Оллоҳ, энди Мусо (а.с.) га ваҳий юборган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              —Бу мўъжизани кўргандан сўнг халқ, сени ҳақиқатан Мен юборганимга ишонади, — деди Оллоҳ. — Энди қўлингни қўйнингга сол!
              <sup>2</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Мусо қўлини қўйнига солдилар. Чиқариб қарасалар, қўли оппоқ, худди пес бўлган одамларнинг қўлига ўхшаб қолибди!
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              —Қўлингни яна қўйнингга сол! — деди Худо. Мусо (а.с.) қўлини яна тўнига киргиздилар. Чиқариб қарасалар қўли аввалги ҳолига қайтибди.
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <p>
              —Халқ биринчи мўъжизанинг оятига ишонмаса, иккинчисини кўрсатасан, — деди Оллоҳ. — Иккинчи оятга ҳам ишонмаганларга Нил дарёсидан сув олиб қуруқ тупроққа тўкасан. Дарёдан олган сувинг тупроқда қизил қонга айланади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> МУСО (А.С.) ЁРДАМЧИ СЎРАЙДИ </p>
          </div>

          <div className='main_text'>
            <p>
              —Ё Раббим, мен ҳеч қачон гапга уста бўлмаганман, — дедилар ҳазрати Мусо. — Мана ҳозир ҳам қулингга шунча сўз айтдинг, мен буларни Бани Исроилга етказолмайман.  <span className="boldItalic"> «Ё Раббим, қалбимни кенг қил, ишимни осон айла. Тилимдаги тугунни еч, токи улар сўзларимни англасинлар».</span> <span className="italic">Қуръон 20:25-28.</span>
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Инсонга тил берган ким? — деди Худо. — Ким уни гунг ё кар, кўрадиган ё кўрмайдиган қилади? Бас, йўлга туш. Мен Ўзим сенга ёрдам бераман, нима дейишни ўргатаман.
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p >
                <sup>3</sup>      Қуръони каримда ёзилишича, Мусо пайғамбар ўшанда: «Менга ўз аҳлимдан оғам Ҳорунни вазир қилиб бер», деб илтимос қилган эканлар (Тоҳо сураси 20:29-30).
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              —Ё Раббим, кимни истасанг юбор», деб илтижо қилди Мусо пайғамбар.   <sup>3</sup>
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <p>
              <span className="italic">У киши, мен бу ишни қила оламанми, деб шубҳаланаётган эдилар. Бу нарса Оллоҳнинг қаҳрини қўзғаса-да, у кишининг тилаги ижобат бўлди: </span>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p className=""> «Эй Мусо, сенга сўраганинг берилди» </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p >
                <sup>4</sup>      Оллоҳ таоло Ҳорун (а.с.) га йўлга тушишни, Мусо (а.с.) ни кутиб олишни амр қилган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              —Аканг Ҳорун сен учун нотиқ бўлади.<sup>4</sup> — деди Худо. —  Сен унга нима дейиш кераклигини тушунтирасан, у эса халқ олдида сенинг номингдан гапиради. Гапларингни халққа етказади. Мен иккалангизга ёрдам бераман, нима қилиш кераклигини ўргатаман. Энди йўлга туш. Асони ҳам ўзинг билан ол, у билан мўъжизалар кўрсатасан.
            </p>

            <p>
              Мусо (а.с.) кейин: <span className="boldItalic"> «Эй Раббим, мен улардан бир жонни ўлдирганман, улар мени ўлдиришларидан қўрқурман», </span>  дейди. Шунда Оллоҳ: «Қўрқма, сени ўлдирмоқчи бўлганлардан бирортаси ҳам ҳозир тирик эмас»,  деб жавоб беради.
            </p>

            <p className='italic'>
              Оллоҳ субҳанаҳу ва таоло ўшанда Мусо пайғамбарни хизматга даъват этган эди. У сизни ҳам Ўз хизматига чақириши мумкин. Чақирса нима деб жавоб берасиз? Итоат этасизми? Ёки хизматдан бош тортасизми? Биз инсонлар Оллоҳ таолога бандамиз, Оллоҳ таолонинг хизматини қилишимиз керак. Ибодат сўзининг ҳам асл маъноси – хизмат демакдир.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> МУСО (А.С.) НИНГ МИСРГА ҚАЙТИШИ  </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p >
                <sup>5</sup>     Табарий, “Тарих”, 1-жилд, 206-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>
              Шундан кейин Мусо пайғамбар қайнотасининг олдига бориб, кетишга рухсат сўрадилар.   <sup>5</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p >
                <sup>6</sup> «Биз (Мусога) Ўз фазлу марҳаматимиз билан оғаси Ҳорунни пайғамбар сифатида ҳадя этдик» (Марям сураси, 53).
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Қайнотаси дуо берганидан сўнг хотин-бола-чақасини эшакка миндириб, Худо буюрган асони қўлга олиб, Мисрга қараб йўлга чиқдилар. Тури Синода акаси Ҳорун билан учрашиб,<sup>6</sup> Оллоҳ айтган ҳамма гапларни, Мисрда кўрсатиши керак бўлган ҳамма оят-белгиларни акасига гапириб бердилар.
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <p>
              Мисрга етиб келганларидан сўнг Бани Исроилнинг барча оқсоқолларини тўпладилар. Ҳорун (а.с.) Худо айтган сўзларни уларга етказди. Кейин Мусо пайғамбар, айтилган гапларни мўъжизалар ояти билан исботладилар. Халқ ишонди. Оллоҳ таоло уларнинг мусибатини кўрганини, уларга назар солганини эшитиб, ҳаммалари саждага бош қўйдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ФИРЪАВН ҲУЗУРИДА  </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p >
                <sup>7</sup>  «Биз Мусони Ўз оятларимиз билан Фиръавн ва унинг одамларига юборган эдик, улар (у оятларга) золим-кофир бўлдилар. Ана энди бузғунчиларнинг оқибатлари қандай бўлганини кўринг» (Аъроф сураси, 103).
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
              Кейин Мусо ва Ҳорун (а.с.) Бани Исроилнинг оқсоқоллари билан бирга фиръавннинг олдига бордилар: «Аълоҳазрат, Оллоҳ сизга, Менинг халқимни қўйиб юбор, саҳрога бориб Менга ибодат қилсин, деб амр қилди», деб айтдилар.<sup>7</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p >
              Шунда фиръавн: «Сизларнинг Оллоҳингиз ким бўпти-ки, мен унинг гапи билан Бани Исроилни қўйиб юборсам?! — деб жавоб берди. — Мен Оллоҳни танимайман».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}
            >
              <p >
                <sup>8</sup> Ўша пайтларда Миср халқи бутпараст бўлиб, кўп худоларга сиғинар эди. Шунинг учун Мусо ва Ҳорун (а.с.) лар Оллоҳни кейин “бизнинг Худомиз” деб тушунтиришга мажбур бўлдилар.
              </p>
            </div>
            <p onClick={() => handleSupClick(8)}>
              «Аълоҳазрат, бизнинг Худойимиз<sup>8</sup> бизга Ўзининг хоҳиш-иродасини нозил қилди. Рухсат беринг, уч кунлик йўл юриб Унга қурбонлик келтирайлик, бўлмаса бошимизга қилич ё офат юбориши мумкин», дейишди улар.
            </p>
            <p>
              «Эй Мусо билан Ҳорун, — деди фиръавн. — Нега шунча одамни ишдан қолдирасиз? Қани тарқалинглар, ҳамма ишига жўнасин!»
            </p>
            <p>
              Кейин ўз нозирлари ва ишчибошиларига бундай деб фармон берди: «Ғишт қуйиш учун энди ибронийларга сомон берманглар. Керак сомонни ўзлари қидириб топсин. Ғиштлар сони эса аввалгидан кам бўлмасин. Кўринишдан, уларнинг иши унча оғирга ўхшамайди. Бўлмаса, кетамиз, қурбонлик қиламиз, деган гаплар мияларига урмас эди. Ишни шундай оғирлаштирингки, иш билан банд бўлиб, беҳуда гапларни эшитишга вақти қолмасин».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЗУЛМНИНГ КУЧАЙИШИ </p>
          </div>

          <div className='main_text'>
            <p>
              Сўнгра назоратчилар ва ишчибошилар Исроил халқига фиръавннинг ҳукмини эълон қилдилар: «Фиръавн фармонига биноан, бундан кейин ғишт тайёрлаш учун сизларга сомон берилмайди. Сомонни ўзингиз топасиз. Ғиштларнинг кундалик миқдори аввалгидан кам бўлмасин».
            </p>
            <p>
              Шундан сўнг Бани Исроил, сомон ахтариб бутун Мисрга тарқалди. Мисрлик назоратчилар эса: «Ғиштлар сони камаймасин», деб зуғум ўтказар, иброний ишчибошиларни «Нега вазифангизни аввалгидек бажармаяпсиз?!» деб калтаклар эди.
            </p>
            <p>
              Ниҳоят улар фиръавннинг олдига шикоят қилиб бордилар: «Нега бизни бунчалик қийнашади? Сомон бермай, ғишт миқдорини аввалгидек талаб қилишяпти. Бесабаб калтаклашяпти. Айб бизда эмас-ку!»
            </p>
            <p>
              «Сизлар танбалсизлар-танбал, — деди фиръавн. — Бўлмаса, бизга рухсат беринг, бориб Худомизга қурбонлик қиламиз, де­б айтмас эдингиз. Боринг, ишингизни қилинг! Сизларга бир ҳовуч ҳам сомон берилмайди!»
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[9] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9)}
            >
              <p >
                <sup>9</sup>  Қуръонда ёзилишича Мусо (а.с.) ўз қавмига: «Оллоҳдан ёрдам сўранглар ва сабр қи­линг­лар. Ер Оллоҳники, уни бандаларидан кимни хоҳласа, ўшанга мерос қилиб беради. Ва оқибат тақводор­лар­ни­ки­дир», деб насиҳат қилган экан­лар. Улар эса: «Сен бизга келмасингдан олдин ҳам, келганингдан кейин ҳам озор тортдик», дейишади. Шунда Мусо пайғамбар: «Шоядки Раб­бин­гиз душманларингизни ҳалок этиб, сизларни ер юзи­га халифа қилиб, қандай амал қилишингизга назар сол­са», деб айтадилар (Аъроф су­ра­си, 128-129).
              </p>
            </div>
            <p onClick={() => handleSupClick(9)}>
              Ишчибошилар буни эшитиб, ёмон вазиятга тушиб қолганларини ҳис қилдилар. Подшоҳ саройидан чиқар эканлар, қаср ташқарисида кутиб турган Мусо билан Ҳорун (а.с.) ни учратиб, уларга: «Оллоҳ жазойингизни берсин! Сизлар фиръавнни ва сарой аҳлини бизга қарши қилиб қўйдингиз! Бизни ўлдириш учун уларнинг қўлига қилич тутқаздингиз», деб айтдилар.<sup>9</sup>
            </p>
            <p>

            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ХУДОНИНГ ВАЪДАСИ </p>
          </div>

          <div className='main_text'>
            <p>
              Шунда ҳазрати Мусо, бориб Оллоҳга дуо қилиб дедилар: «Ё Раббим! Нима учун бу халққа ёмонликни раво кўрдинг? Мени бу ерга шу мақсад билан олиб келган эдингми? Сўзларингни фиръавнга етказганимдан бери у бу халққа зулм қилмоқда. Сен эса халқингга нажот бермадинг!»
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[10] ? 'block' : 'none' }}
              onClick={() => handleSupClick(10)}
            >
              <p >
                <sup>10</sup> Худди ҳозир ибронийлар Миср юртида келгинди бўлиб яшаётганларидек, Иброҳим (а.с.) ҳам, Исҳоқ ва Яъқуб пайғамбарлар ҳам, Канъон юртида уйсиз-жойсиз, мусофир бўлиб яшаган эдилар.
              </p>
            </div>
            <p onClick={() => handleSupClick(10)}>
              «Фиръавн билан нима бўлишини ҳали кўрасан, — деди Худо. — У сизларни нафақат озод қилади, балки зўрлаб Мисрдан чиқаради. Мен Иброҳим, Исҳоқ ва Яъқуб билан аҳд қилиб, Канъон мулкини сизларнинг авлодингизга бераман,<sup>10</sup> деб ваъда қилганман. Бор, Бани Исроилга айт, Мен ал-Қайюмман. Мен сизларни буюк қудрат ва оят-мўъжизалар билан қулликдан озод қиламан. Шунда сизлар Менинг aл-Қайюм эканимга амин бўласизлар. Сизлар Менинг халқим бўласизлар, Мен сизларнинг Худойингиз бўламан. Сизларни Мисрдан олиб чиқиб, ваъда қилган юртимга олиб бораман».
            </p>
            <p>
              Мусо (а.с.) Оллоҳ таолонинг гапларини Бани Исроилга айтдилар. Лекин оғир меҳнат ва калтакдан руҳи синган ибронийлар у кишининг гапига қулоқ солмади.
            </p>
            <p></p>
          </div>








          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p> Учта мўъжиза</p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/Egypt-plagues')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > Офатлар </span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
         <ThanksComponent />
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default EXAMPLE
