import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './languageSwitcher.css';

import englishFlag from '../images/flag_English.png';
import turkishFlag from '../images/flag_Turkish.png';
import uzbekFlag from '../images/flag_Uzbek.png';
import dutchFlag from '../images/flag_Dutch.png';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setIsOpen(false);
  };

  const languageOptions = [
    { code: 'en', flag: englishFlag, alt: 'English' },
    { code: 'tr', flag: turkishFlag, alt: 'Turkish' },
    { code: 'uz', flag: uzbekFlag, alt: 'uzbek' },
    { code: 'nl', flag: dutchFlag, alt: 'Dutch' }
  ];

  return (
    <div className="languageSwitcherContainer">
      <div className="languageSwitcher" onClick={toggleDropdown}>
        <img
          src={languageOptions.find(lang => lang.code === selectedLanguage).flag}
          alt={selectedLanguage}
          className="languageFlag"
        />
        {isOpen && (
          <div className="dropdownMenu">
            {languageOptions
              .filter(lang => lang.code !== selectedLanguage)
              .map(lang => (
                <div key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
                  <img src={lang.flag} alt={lang.alt} className="dropdownFlag" />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default LanguageSwitcher;