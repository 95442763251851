import React from 'react'

const WhichCoursesToFollow = () => {
  return (
    <div className="right_column_posts">
       <h3>Which courses to follow</h3>
              <div className="spacer10px"></div>
              <p>Lorem ipsum dolor right_column for Students amet consectetur, adipisicing elit. Earum dolor similique odio aperiam ad placeat ue, dolore  ab quibusdam repellat. Perferendis, illo.</p>
    </div>
  )
}

export default WhichCoursesToFollow