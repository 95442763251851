import bookmark1 from '../../../images/bookmark1.png';
import bookmark2 from '../../../images/bookmark2.png';
import { usePostBookmark } from '../../../hooks/usePostBookmark';

const BookmarkingButton = ({ post, globalPostsArrayName }) => {

  const { isBookmarked, handleBookmark } = usePostBookmark(globalPostsArrayName, post);

  return (
    <button title={isBookmarked ? "Remove Bookmark" : "Add Bookmark"} onClick={handleBookmark} className='bookmark'>
      <div className='img_contain'>
        <img src={isBookmarked ? bookmark2 : bookmark1} alt="" />
      </div>
    </button>
  );
};

export default BookmarkingButton;
