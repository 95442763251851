import React, { useState } from 'react';
import { format } from 'date-fns';
import './eventCreate.css'

import report from '../images/flag.png';
import share from '../images/share.png';
import create_event from '../images/create_event.png';

const EventCreate = () => {

  const [formattedDate, setFormattedDate] = useState('');

  const [isPopOn, setPopOn] = useState(false);
  const [selectedColor, setSelectedColor] = useState('black');

  const [realTimeEventName, setRealTimeEventName] = useState('Event name');
  const [realTimeGroupName, setRealTimeGroupName] = useState('Speaker\'s name');
  const [realTimeDate, setRealTimeDate] = useState('20/03/2024');
  const [realTimeDescription, setRealTimeDescription] = useState('Lorem ipsum dolo renacom sebar ik dunno. Maar wat is dat? Perhaps you should not pisat stuff here, if you dunno how to write. No, I am wrong, write anythiing becuuse this way you learn spelling art.');
  const [realTimeCommonInterest, setRealTimeCommonInterest] = useState('Example of topic name');

  const [nameError, setNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [commonInterestError, setCommonInterestError] = useState('');

  const [formData, setFormData] = useState({
    eventName: '',
    groupName: '',
    groupDescription: '',
    commonInterest: '',
    selectedColor: 'black',
    selectedImage: '',
    selectedTheme: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if there are errors, and if so, prevent form submission
    if (nameError || descriptionError || commonInterestError) {
      return;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'eventDate') {
      setRealTimeDate(value);
      const formattedDate = format(new Date(value), '29/02/2024');
      setFormattedDate(formattedDate);  // Update the formattedDate state
      setFormData({
        ...formData,
        [name]: formattedDate,
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === 'selectedColor') {
      setSelectedColor(value);
    }


    if (name === 'groupName') {
      if (value.length < 4 || value.length > 17) {
        setNameError('Enter 4-17 characters');
      } else {
        setNameError('');
      }
    }

    if (name === 'groupDescription') {
      if (value.length < 5 || value.length > 100) {
        setDescriptionError('Enter 4 - 57 characters');
      } else {
        setDescriptionError('');
      }
    }

    if (name === 'commonInterest') {
      if (value.length < 4 || value.length > 57) {
        setCommonInterestError('Enter 4 - 57 characters');
      } else {
        setCommonInterestError('');
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'groupName') {
      setRealTimeGroupName(value);
    } else if (name === 'eventName') {
      setRealTimeEventName(value);
    } else if (name === 'groupDescription') {
      setRealTimeDescription(value);
    } else if (name === 'commonInterest') {
      setRealTimeCommonInterest(value);
    }
    // } else if (name === 'commonInterest') {
    //   setRealTimeCommonInterest(value);
    // }
  };

  return (
    <div style={{ margin: 'auto', marginRight: '0px' }}>
      <button
        style={{ opacity: '0.6' }}
        title="create new event, e.g. webinar, seminar, BBQ party etc. "
        className="commune_create_icon"
        onClick={() => {
          setPopOn(true);
        }}
      >
        <img src={create_event} alt="" />
      </button>
      {isPopOn && (
        <div className='event_create_container'
          onClick={() => setPopOn(false)} >
          <div className='event_create' onClick={(e) => {
            e.stopPropagation();
          }} >

            <button className="close_event_create" onClick={() => setPopOn(false)}>
              &times;
            </button>

            <div className="two_column_contain">
              <form onSubmit={handleSubmit} className='webinar_form'>
                <h2>Cr<span>e</span>at<span>e</span> an <span>e</span>v<span>e</span>nt</h2>
                <div>
                  <div className='container_of_two'>
                    <div>
                      <label> <span className='mandatory'>* </span > What kind of event:</label>
                      <input
                        type="text"
                        name="eventName"
                        // style={{ textAlign: "center" }}
                        placeholder="webinar/BBQ party/sport event..."
                        value={formData.eventName}
                        maxLength="17"
                        onChange={handleInputChange}
                        autoComplete="off"
                      // dir="rtl"
                      />

                      <div className="for_error">

                      </div>
                    </div>

                    <div className="event_date_container">
                      <p className='enter_the_date'>  <span className='mandatory'>* </span > Enter the date: </p>
                      <div className='date_of_event_div'>
                        <label htmlFor='date_of_event' className='even_date'>
                          <span className='mandatory'>* </span > When happens:</label>
                        <input
                          id='date_of_event'
                          type='date'
                          name='eventDate'
                          value={formData.eventDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>


                  </div>

                  <div className='container_of_two topic_describing'>
                    <div>
                      <label><span className='mandatory'>* </span > Topic:</label>
                      <input
                        type="text"
                        name="commonInterest"
                        placeholder="Choose a topic"
                        value={formData.commonInterest}
                        onChange={handleInputChange}
                        maxLength="57"
                        autoComplete="off"
                      />
                      <div className="for_error">
                        {commonInterestError && <p className="error-message">{commonInterestError}</p>}
                      </div>
                    </div>

                    <div>
                      <label> <span className='mandatory'>* </span > Who are the speakers:</label>
                      <input
                        type="text"
                        name="groupName"
                        // style={{ textAlign: "center" }}
                        placeholder="Their names"
                        value={formData.groupName}
                        maxLength="17"
                        onChange={handleInputChange}
                        autoComplete="off"
                      // dir="rtl"
                      />
                      <div className="for_error">
                        {nameError && <p className="error-message">{nameError}</p>}
                      </div>
                    </div>
                  </div>

                  <div className="container_of_two event_describing">
                    <div>
                      <label> <span className='mandatory'>* </span > Event Description:</label>
                      <input
                        type="text"
                        name="groupDescription"
                        placeholder="Highlight main points"
                        value={formData.groupDescription}
                        onChange={handleInputChange}
                        maxLength="140"
                        autoComplete="off"
                      />

                      <div className="for_error">
                        {descriptionError && <p className="error-message">{descriptionError}</p>}
                      </div>

                    </div>

                    <div className='pick_color'>
                      <label htmlFor="">Pick a color:</label>
                      <select
                        name="selectedColor"
                        value={formData.selectedColor}
                        onChange={handleInputChange}
                      >
                        <option value="black">black</option>
                        <option value="red">red</option>
                        <option value="blue">blue</option>
                        <option value="green">green</option>
                        <option value="purple">purple</option>
                        <option value="gold">gold</option>
                      </select>
                    </div>
                  </div>

                  <button type="submit"
                    className={`submit_btn ${nameError || descriptionError || commonInterestError ? 'disabled-button' : ''}`}
                    disabled={nameError || descriptionError || commonInterestError || !formData.selectedImage}>
                    Enter more details
                  </button>

                </div>


              </form>
              {/* </div> */}

              <aside>

                <div className="heading">
                  <h4 style={{ textAlign: 'center' }}>Your form will be displayed as:</h4>
                </div>
                <div className="appearance">
                  <header title="knock the door to enter the chat">
                    <div>
                      <h4> {realTimeEventName}: <span style={{ fontSize: '12px' }}>{formattedDate} </span></h4>
                    </div>

                    <div className='report_div' title='report this event'>
                      <img src={report} alt="" />
                    </div>
                  </header>

                  <main className="event_info">

                    <div>
                      <h4 className={selectedColor + 'Text'}
                        title='topic of the group'>
                        <span>Topic:</span> {realTimeCommonInterest}
                      </h4>

                      <div>
                        <h4 className=""> Speaker: {realTimeGroupName}
                        </h4>
                      </div>
                    </div>


                    <div className="annotatie">
                      <p>{realTimeDescription}</p>
                    </div>

                    <button className='more_info_btn'>
                      More info
                    </button>

                    <div className='share_div' title='share this information with others'>
                      <img src={share} alt="" />
                    </div>
                  </main>
                </div>
                <div className='warning'>
                  <span>Caution:</span> Please thoroughly review all information before finalizing your event.
                </div>
              </aside>
            </div>
          </div >
        </div >
      )}
    </div>
  )
}

export default EventCreate