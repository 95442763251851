import React, { useState} from 'react';
// import post_something from '../../images/post_new.png';
import PostCreate from '../../feature/posts/components/PostCreate';
import  './createPostButton.css';

const CreatePostButton = () => {

  const [isPostCreateOpen, setPostCreateOpen] = useState(false)

  return (
    <>
      <button className="post_something"
        title='create and publish a post'
        onClick={() => {
          setPostCreateOpen(true)
        }}
      >
        {/* <img src={post_something} alt="" /> */}
        Post
      </button> 

      {isPostCreateOpen && <PostCreate setPostCreateOpen={setPostCreateOpen} />}
      </>
    );
};

export default CreatePostButton;