import React, { useState } from 'react';
import './contactUs.css';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const ContactUs = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [email, setEmail] = useState('');
  const [adminNote, setAdminNote] = useState('There are no admin notes yet');

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [goodNews, setGoodNews] = useState('');
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    explanation: '',
  });

  const handleTextareaChange = (e) => {
    setFormData({ ...formData, explanation: e.target.value });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendMessage = async () => {

    const requestBody = {
      ...formData,
      userId: auth.userId,
      email: email,
      adminNote: adminNote,
    };

    const isValid = formData.explanation.trim() !== '';

    if (isValid) {
      try {
        await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/api/contactUs/`, requestBody);

        setEmail('');
        setFormData({ explanation: '' });
        setGoodNews('Your message is sent!');

        setTimeout(() => {
          setGoodNews('');
        }, 3000);

        setTimeout(() => {
          setPopupOpen(false);
        }, 7000);

      } catch (error) {
        setError('Server side error, try later');

        setTimeout(() => {
          setError('');
        }, 3000);

        console.error('Error sending note:', error);
      }
    } else {
      setError('Did you write your message?');
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };



  return (
    <div className="contact_us">
      <h3>Contact us:</h3>
      <ul>

        <li>
          <input type="text" value={email} onChange={handleEmailChange} placeholder='Your email (optional)' />
        </li>
        <li>
          <textarea
            id='message'
            name="message"
            cols="30"
            rows="4"
            placeholder="Write your message"
            value={formData.explanation}
            onChange={handleTextareaChange}
          ></textarea>
          <div className='flex_it'>
            <button onClick={handleSendMessage}>Send</button>

            {error && (
              <div className="error">
                <div>
                  <p>{error}</p>
                </div>
              </div>
            )}
            {goodNews && (
              <div className="goodNews">
                <div>
                  {' '}
                  <p>{goodNews}</p>
                </div>
              </div>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ContactUs;
