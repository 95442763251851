import React from 'react'
import './eventThumbNail.css'

const EventThumbNail = () => {
  return (
    <div className="event_thumbnail">
      <header>
        <div>
          <h2 className="">SEMiNAR</h2>
          <div style={{ margin: 'auto', paddingTop: '2px' }}> <p>18/03/2024</p></div>
        </div>
        <button className='see_all_events' title='click to navigate'>
          <p style={{fontSize: '0.75rem'}}>other events</p>
        </button>

      </header>
      <main className='container'>
        <div className="spacer10px"></div>
        <h5 style={{color: 'green'}}> <span style={{ fontWeight: '500', color: 'gray', marginRight: '10px'}}>Speaker: </span> Ruzmat Xorazmiy</h5>
        <h5> <span style={{ fontWeight: '500', color: 'gray', marginRight: '10px'}}>Topic: </span>  Discovering Marriage</h5>
        <div className="spacer10px"></div>
        <p style={{ fontSize: '0.75rem'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum dolor similique odio aperiam ad placeat ue, dolore temporibus ab quibusdam repellat. Perferendis, eveniet illo.</p>
        <div className="spacer20px"></div>
        <button>More info</button>
      </main>
    </div>
  )
}

export default EventThumbNail